<template>
  <v-app :style="backgroundStyle">
    <v-main>
      <router-view />
    </v-main>
    <ag-notify />
  </v-app>
</template>

<script>
import AgNotify from 'src/components/common/AgNotify'

export default {
  name: 'EmptyLayout',
  components: {
    AgNotify,
  },
  props: {
    backgroundImage: {
      type: String,
      default: null,
    },
  },
  computed: {
    backgroundStyle () {
      if (!this.backgroundImage) {
        return {}
      }

      const image = `url(${this.backgroundImage})`
      const gradient = 'linear-gradient(#FFFFFFC0, #FFFFFFC0)'
      return {
        'background-image': `${gradient}, ${image}`,
        'background-size': 'cover',
        'background-position': 'center',
        'background-repeat': 'no-repeat',
      }
    },
  },
}
</script>
